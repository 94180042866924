//@import "./fonts";
@import "./mixin";

* {
  box-sizing: border-box;
  scroll-margin-top: 120px;


  &::-webkit-scrollbar {
    width: 6px;

    &-track {
      background: transparent;
    }

    &-thumb {
      border-radius: 100px;
      background-color: var(--grey-color);
    }
  }
  &::-moz-selection {
    background: var(--grey-lines-color);

  }

  &::selection {
    background: var(--grey-lines-color);
  }
}

:root {
  --primary-color: #ffdd2d;
  --boxshadow-primary-color: rgba(245, 195, 0, 0.5);
  --hover-primary-color: #fcc521;
  --orange-color: #fcbc17;
  --secondary-color: #1d1d1d;
  --white-color: #ffffff;
  --grey: #9e9e9e;
  --grey-color: #616161;
  --grey-secondary: #e9e9e9;
  --grey-light-color: #c9ced6;
  --grey-darker-color: #9ea0a3;
  --grey-light-secondary: #f7f7f7;
  --grey-light-third: #f2f2f2;
  // ----
  --beige-p-color: #e3d1ac;
  --yellow-p-color: #ffe99d;
  --yellow-p-secondary-color: #f0d756;
  --grey-oragne-p-color: #ccc2b2;
  --grey-p-color: #e4d6cd;
  // ----
  --grey-fourth: #a3a3a3;
  --grey-third: #9ea0a3;
  --black-color: #000000;
  --grey-lines-color: #e3e3e3;
  --blue-background-color: #f7f8f9;
  --blue-color: #105ca8;
  --red-color: #ac0401;
  --red-error: rgba(225, 29, 72, 1);
  --red-secondary-color: rgba(225, 29, 71, 0.5);
  --red-danger-color: #e54f6d;
  --green-color: #1ab28f;
  --green-secondary: #82c21d;
  --label-new-color: #f0ffd0;
  --label-license-color: #ffd8bc;
  --label-sale-color: #ffefc5;
  --remington-color: #e5b892;
  --border-radius-small: 8px;
  --border-radius-normal: 16px;
  --border-radius-medium: 24px;
  --fg-error: rgba(225, 29, 72, 1);
  --border-error: rgba(225, 29, 72, 1);
}

body,
html {
  @include clearStyles;
  // min-width: 320px;
  //font-family: "Gilroy";
  font-size: 14px;
  line-height: 24px;
  scroll-behavior: smooth;
  background-color: var(--white-color);
  color: var(--black-color);
}

body {
  position: relative;
  min-height: 100dvh;
  display: flex;
  flex-direction: column;
}

h1,
h2,
h3,
h4,
h5,
p,
a,
li,
label,
span,
div {
  @include clearStyles;
}

ul {
  @include clearStyles;
  list-style-type: none;
}

a {
  text-decoration: none;
  color: var(--black-color);
}

input {
  @include clearStyles;
  background-color: transparent;
  border: none;
  outline: none;
  border-radius: unset;
  appearance: none;

  &[type="number"] {
    appearance: none;
    -moz-appearance: textfield;

    &:hover,
    &:focus {
      appearance: none;
      -moz-appearance: textfield;
    }

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
    }
  }
}

button {
  @include clearStyles;
  border: none;
  cursor: pointer;
  background-color: transparent;
  color: var(--black-color);
}

img {
  width: 100%;
}

main {
  padding-bottom: 100px;
  min-height: calc(100svh - 118px);

  @media only screen and (min-width: 320px) and (max-width: 767.98px) {
    padding-bottom: 60px;
    min-height: calc(100svh - 66px);
  }
}

section {
  padding: 20px 0;

  @media only screen and (min-width: 768px) and (max-width: 1023.98px) {
    padding-bottom: 6px;
  }

  @media only screen and (min-width: 320px) and (max-width: 767.98px) {
    padding: 12px 0 16px;
  }
}

.container {
  margin: 0 auto;
  padding: 0 120px;
  width: 100%;
  flex: 1;
  max-width: 1680px;

  @media only screen and (min-width: 1024px) and (max-width: 1439.98px) {
    max-width: 1320px;
    padding: 0 100px;
  }

  @media (min-width: 768px) and (max-width: 1023.98px) {
    max-width: 924px;
    padding: 0 50px;
  }

  @media screen and (max-width: 767.98px) {
    max-width: 736px;
    padding: 0 16px;
  }
}

.visually-hidden {
  //position: absolute;
  //clip: rect(0 0 0 0);
  //width: 1px;
  //height: 1px;
  //margin: -1px;
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  border: 0;
  padding: 0;
  white-space: wrap;
  clip-path: inset(100%);
  clip: rect(0 0 0 0);
}

[data-is-not-valid="true"] {
  border-color: var(--red-color) !important;
  animation: 0.1s tremor ease-out 1;
}

[data-style-custom="links-wrap"] {
  display: flex;
  flex-wrap: wrap;
  gap: 4px;
}

[data-style-custom="custom-desc-link"] {
  background-color: var(--blue-background-color);
  padding: 4px 8px;
  border-radius: var(--border-radius-small);
  border: none;
  transition: 0.25s;

  &:hover {
    background-color: var(--primary-color);
  }

  &::after,
  &::before {
    display: none;
  }
}

@keyframes tremor {
  0%,
  25% {
    left: -1px;
    transform: translateX(-1%);
  }

  50%,
  100% {
    left: 1px;
    transform: translateX(1%);
  }
}

.consultant-icon {
  background-color: var(--primary-color) !important;

  @media only screen and (max-width: 767px) {
    width: 0 !important;
    height: 0 !important;
  }
}

.consultant-widget-wrap {
  visibility: hidden !important;
  pointer-events: none !important;
}

.messengers__item[data-v-a2b57e0e] {
  @media only screen and (max-width: 767px) {
    visibility: hidden !important;
    pointer-events: none !important;
  }
}

.consultant-header[data-v-5caba0b6],
  // .contact-form__btn[data-v-621716fe] {
.contact-form__btn {
  background-color: var(--primary-color) !important;
  color: var(--black-color) !important;
  -webkit-text-fill-color: var(--black-color) !important;
}

.consultant-header__name[data-v-5caba0b6] {
  color: var(--black-color) !important;
}

.consultant-header__close svg[data-v-5caba0b6] {
  fill: var(--black-color) !important;
}

#retailcrm-consultant-app {
  z-index: 1;
}

.consultant-widget-wrap[data-v-47086672] {
  @media only screen and (max-width: 767px) {
    display: none !important;
  }
}

.grecaptcha-badge {
  display: none;
}
